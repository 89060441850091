/* eslint-disable */
import React, { Fragment, useState } from "react";
import SideBar from "./SideBar";
import NavHeader from "./NavHeader";
import Header from "./Header";
import {useLocation} from 'react-router-dom';

const WeldCreateNav = ({ title, isAuthenticating }) => {

   const [toggle, setToggle] = useState("");
   const onClick = (name) => setToggle(toggle === name ? "" : name);

   return (
      <Fragment>
        {!isAuthenticating ? <><NavHeader /><SideBar /></> : null}
         
         <Header
            onNotification={() => onClick("notification")}
            onProfile={() => onClick("profile")}
            toggle={toggle}
            title={title}
            isAuthenticating={isAuthenticating}
         />
      </Fragment>
   );
};

export default WeldCreateNav;
