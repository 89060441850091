import React from "react";
import { Table } from "react-bootstrap";

const CourseList = ({data}) => {
 
   
    return (
      <div className="w-100 mx-0 mx-md-auto row justify-content-center">
 <Table
                              id="list"
                              className="display w-100 dataTable"
                           >
                        <div
                           id="list_wrapper"
                           className="dataTables_wrapper"
                        >
                           <table
                              id="list"
                              className="display w-100 dataTable"
                           >
                              <thead>
                                 <tr role="row">     
                               <th>Name</th>
                                 </tr>
                              </thead>
                              <tbody>
        {data.map((Course, i) => {
          return <tr key={i + 'qli'}><td>{Course.name}</td></tr>;
        })}
        </tbody></table>
      </div></Table></div>
    ); 

}

export default CourseList;