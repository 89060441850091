/* eslint-disable */
import React, {useState, useEffect} from "react";
import { Button } from "react-bootstrap";

const NewCategory = () => {
  const [name, setName] = useState("");
  const [Description, setDescription] = useState("");

  return (
    <div className="justify-content-center  align-items-center h-80 w-100">
      <div>
        <div className="form-input-content text-center error-page">
          <h1 className="font-weight-bold mb-5">Create a Category</h1>
          <div className={"card p-5 w-50 m-auto text-left"}>
            <div className="form-group">
              <label className="text-black">
                <strong>Category Name</strong>
              </label>
              <input
                type="text"
                className="form-control w-100"
                name="name"
              />
            </div>
            <div className="form-group">
              <label className="text-black">
                <strong>Category Description</strong>
              </label>
              <textarea
                type="text"
                className="form-control w-100"
                name="Description"
                style={{resize: "none"}}
                rows="4"
              />
            </div>
            <Button className={'text-uppercase'}>Create</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCategory;
