/* eslint-disable */
import React, { Component } from "react";
/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

class MM extends Component {
   constructor() {super();}
   componentDidMount() {
      this.$el = this.el;
      this.mm = new MetisMenu(this.$el);
   }
   componentWillUnmount() {
   
      if (this) {
         (() => {
            this.mm("dispose");
         })();
      };

   }
   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}

class SideBar extends Component {
   /// Open menu
   componentDidMount() {
      // sidebar open/close
      var btn = document.querySelector(".nav-control");
      var aaa = document.querySelector("#main-wrapper");

      function toggleFunc() {
         return aaa.classList.toggle("menu-toggle");
      }

      btn.addEventListener("click", toggleFunc);
   }
   render() {
      /// Path
      const path = window.location.pathname;

      /// Active menu
      let queries = [
         "",
         "categories",
         "courses"
      ]
         ;

      return (
        <div className="deznav">
          <PerfectScrollbar className="deznav-scroll">
            <MM className="metismenu" id="menu">
           
              <li className={path.includes("courses") ? "mm-active" : ""}>
         <Link
           className="has-arrow ai-icon"
           to="/courses"
           aria-expanded="false"
         >
           <i className="flaticon-381-notebook-2"></i>
           <span className="nav-text">E-Learning</span>
         </Link>
         <ul aria-expanded="false">
           <li>
             <Link to="/courses/">Courses</Link>
           </li>
           <li>
             <Link to="/categories/">Categories</Link>
           </li>
         </ul>
       </li>
            </MM>

            <div className="copyright">
              <p>
                <strong>Create</strong> ©All Rights Reserved
              </p>
              <p>by Weld</p>
            </div>
          </PerfectScrollbar>
        </div>
      );
   }
}

export default SideBar;
