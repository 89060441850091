/* eslint-disable */

import React, { useState } from "react";
import { STRMCOLORS, sampleData, showsByGenre, genreData } from "../../../constants/data";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more.js";
import drilldown from "highcharts/modules/drilldown";
highchartsMore(Highcharts);
drilldown(Highcharts);

const BubbleChart = ({ initialData, drilldownData, updateTableData }) => {
  const [data, setData] = useState(initialData);
  Highcharts.setOptions({
    colors: STRMCOLORS,
  });
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            type: "packedbubble",
            height: "auto",
            events: {
              drillup: function (event) {
             
                if (event.seriesOptions.name == "Genre") {
                  updateTableData(false);
                }
              },
            },
          },
          title: false,

          plotOptions: {
            packedbubble: {
              events: {
                click: function (event) {

                  const eventDrilldown = event.point.options.drilldown;
                 
              if(typeof eventDrilldown !== 'undefined') {
                const nextDataSet = eventDrilldown
                  ? drilldownData.series.filter((e) => {
                      return e.name.toLowerCase() == eventDrilldown;
                    })
                  : genreData;

                const newSeries =
                  nextDataSet.length > 0 ? nextDataSet[0] : false;

          

                if (newSeries) {
                  updateTableData(newSeries);
                }
              }
                },
              },
              layoutAlgorithm: {
                splitSeries: true,
                enableSimulation: false,
              },
              dataLabels: {
                enabled: true,
                format: "{point.name}",
                parentNodeFormat: "{series.name}",
              },
            },
          },
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {
                  legend: {
                    enabled: false,
                  },
                },
              },
            ],
          },
          series: data,

          drilldown: drilldownData,
        }}
      />
    </div>
  );
};

export default BubbleChart;
