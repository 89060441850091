/* eslint-disable */
import React from "react";

/// React router dom
import { useLocation, BrowserRouter as Router, Switch, Route } from "react-router-dom";
/// Css
import "../assets/css/index.css";
import "../assets/css/chart.css";

/// Layout
import Nav from "../view/layout/nav";
import Footer from "../view/layout/Footer";
import Registration from "../view/pages/Registration";
import ComingSoon from "../view/pages/ComingSoon";
import Categories from "./Categories/Categories";
import Category from "./Categories/CategoryDetail";
import NewCategory from "./Categories/NewCategory";
import Courses from "./Courses/Courses";
import Course from "./Courses/CourseDetail";
import NewCourse from "./Courses/NewCourse";

const Markup = () => {
  
   const routes = [
      /// Queries
      { url: "", component: Courses },
      { url: "categories", component: Categories},
      { url: "categories/id", component: Category},
      { url: "categories/new", component: NewCategory },
      { url: "courses", component: Courses},
      { url: "courses/id", component: Course},
      { url: "courses/new", component: NewCourse }
   ];

   const path = window.location.pathname;
   const isAuthenticating = (path.indexOf('login') > -1 || path.indexOf('register') > -1) ? true : false;
   return (
      <Router basename="/">
         <div id="main-wrapper" className={"show " + (isAuthenticating ? ' auth' : null)} >
            <Nav isAuthenticating={isAuthenticating}/>
            <div className="content-body">
               <div className="container-fluid">
                  <Switch>
                     {routes.map((data, i) => (
                        <Route
                           key={i}
                           exact
                           path={`/${data.url}`}
                           component={data.component}
                        />
                     ))}
                  </Switch>
               </div>
            </div>
            <Footer />
         </div>
      </Router>
   );
};

export default Markup;
