/* eslint-disable */
import React from "react";

const Footer = () => {
   return (
      <div className="footer">
         <div className="copyright">
            <p>
               Copyright &copy;{" "}
               <a href="https://weld.co/" target="_blank">
                  Weld
               </a>{" "}
               {new Date().getFullYear()}
            </p>
         </div>
      </div>
   );
};

export default Footer;
