/* eslint-disable */

import React, { useState } from "react";
import { STRMCOLORS, sampleData, mapInit } from "../../../constants/data";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more.js";
import drilldown from "highcharts/modules/drilldown";
import highchartsMap from "highcharts/modules/map";
import proj4 from "proj4";
import mapDataInterface from "@highcharts/map-collection/countries/us/us-all.geo.json";


highchartsMore(Highcharts);
drilldown(Highcharts);
highchartsMap(Highcharts);



const MapChart = ({ initialData, drilldownData, updateTableData }) => {
  const [data, setData] = useState(initialData);
  const [map, setMap] = useState(mapInit(mapDataInterface));
  return (
    <div className="py-4">
      <HighchartsReact
        constructorType={"mapChart"}
        highcharts={Highcharts}
        options={map.options}
      />
    </div>
  );
};

export default MapChart;
