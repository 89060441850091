/* eslint-disable */
import { useState, useEffect } from "react";
import "../../assets/css/loader.css";
import {
  tabData,
  drilldownData,
  DummyTable,
  genreData,
  STRMCOLORS,
  sampleData,
  showsByGenre,
} from "../../constants/data";
import Loading from "../Common/Loading";
import { Link } from "react-router-dom";
import { Row, Col, Card, Tabs, Tab, Nav } from "react-bootstrap";
import PageTitle from "../../view/layout/PageTitle";
import BubbleChart from "../Common/Charts/BubbleChart";
import MapChart from "../Common/Charts/MapChart";

const CourseDetail = ({ data }) => {
  const [loaded, setLoaded] = useState(false);
  const [tableData, setTableData] = useState(genreData[0]);
  const [tabs, setTabs] = useState([
    <BubbleChart
      initialData={genreData}
      drilldownData={drilldownData}
      updateTableData={(e)=>{setTableData(e)}}
    />,
    <BubbleChart
      initialData={genreData}
      drilldownData={drilldownData}
      updateTableData={(e)=>{setTableData(e)}}
    />,
    <BubbleChart
      initialData={genreData}
      drilldownData={drilldownData}
      updateTableData={(e)=>{setTableData(e)}}
    />,
    <MapChart
      initialData={genreData}
      drilldownData={drilldownData}
      updateTableData={(e)=>{setTableData(e)}}
    />,
  ]);

  useEffect(() => {

    setTimeout(() => {
      setLoaded(true);
    }, 1000);

  });
  return loaded ? (
    <div className="container m-0 m-auto p-0">
      <div className=" d-none d-sm-block">
        <PageTitle activeMenu="Course" motherMenu="Courses" />
      </div>
      <div className="inner">
        <Card>
          <Card.Body>
            <div className="d-inline h3">Course Name</div>
            <div className="pull-right fs-6">edit/delete Icons</div>
            <div className="p-3 mt-2">
              Course Description:
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt. Lorem ipsum dolor sit amet,
                   consectetur adipiscing elit, sed do eiusmod tempor
                   incididunt. Lorem ipsum dolor sit amet, consectetur adipiscing
                   elit, sed do eiusmod tempor incididunt
            </div>
            <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
             
              <Tab.Content>
                {tabData.map((data, i) => (
                  <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                    <div>
                      {tabs[i]}
                      {i < tabs.length - 1 ? <DummyTable
                        type="Show"
                        series={tableData ?? genreData[0]}
                      /> : ""}
                    </div>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default CourseDetail;
