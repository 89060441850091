/* eslint-disable */
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
} from "react-bootstrap";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more.js";
import drilldown from "highcharts/modules/drilldown";
import highchartsMap from "highcharts/modules/map";
import proj4 from "proj4";

export const mapInit = (mapDataInterface) => {
  console.log(mapDataInterface);
  let mapData = Highcharts.geojson(mapDataInterface);
  if (mapData) {

    if (typeof window !== "undefined") {
      window.proj4 = window.proj4 || proj4;
    }
    mapData.forEach((d, i) => {
      d.drilldown = d.properties["hc-key"];
      d.value = i; // Non-random bogus data
    });
    window.Highcharts = Highcharts;
    const mapSeparators = Highcharts.geojson(mapDataInterface, "mapline");
    const mapOptions = {
      chart: {
        events: {
          drilldown: function (e) {
            if (!e.seriesOptions) {
              const chart = this,
                mapKey = "countries/us/" + e.point.drilldown + "-all";

              // Handle error, the timeout is cleared on success
              let fail = setTimeout(() => {
                if (!Highcharts.maps[mapKey]) {
                  chart.showLoading(
                    '<i class="icon-frown"></i> Failed loading ' + e.point.name
                  );
                  fail = setTimeout(() => {
                    chart.hideLoading();
                  }, 1000);
                }
              }, 3000);

              // Show the spinner
              chart.showLoading(
                '<i class="icon-spinner icon-spin icon-3x"></i>'
              ); // Font Awesome spinner
              let Highcharts = window.Highcharts;
              // Load the drilldown map
              const keyurl =
                "https://code.highcharts.com/mapdata/" + mapKey + ".js";
              console.log("keyurl", keyurl);
              getScript(keyurl, () => {
                let data = Highcharts.geojson(Highcharts.maps[mapKey]);

                // Set a non-random bogus value
                data.forEach((d, i) => {
                  d.value = i;
                });

                // Hide loading and add series
                chart.hideLoading();
                clearTimeout(fail);
                chart.addSeriesAsDrilldown(e.point, {
                  name: e.point.name,
                  data: data,
                  dataLabels: {
                    enabled: true,
                    format: "{point.name}",
                  },
                });
              });
            }

            this.setTitle(null, { text: e.point.name });
          },
          drillup: function () {
            this.setTitle(null, { text: "" });
          },
        },
      },

      title: {
        text: "",
      },

      subtitle: {
        text: "",
        floating: true,
        align: "right",
        y: 50,
        style: {
          fontSize: "16px",
        },
      },

      colorAxis: {
        min: 0,
        minColor: STRMCOLORS[0],
        maxColor: STRMCOLORS[1],
      },

      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: "bottom",
        },
      },

      plotOptions: {
        map: {
          states: {
            hover: {
              color: STRMCOLORS[2],
            },
          },
        },
      },

      series: [
        {
          data: mapData,
          name: "USA",
          dataLabels: {
            enabled: true,
            format: "{point.properties.postal-code}",
          },
        },
        {
          type: "mapline",
          data: mapSeparators,
          color: STRMCOLORS[STRMCOLORS.length-1],
          enableMouseTracking: false,
          animation: {
            duration: 500,
          },
        },
      ],

      drilldown: {
        activeDataLabelStyle: {
          color: "#FFFFFF",
          textDecoration: "none",
          textOutline: "1px #000000",
        },
        drillUpButton: {
          relativeTo: "spacingBox",
          position: {
            x: 0,
            y: 60,
          },
        },
      },
    };
    return { options: mapOptions, data: mapData, seperators: mapSeparators };
  }
}

export const STRMCOLORS = [
  "#9655ca",
  "#ffa45e",
  "#a13670",
  "#4064A7",
  "#092C6D",
  "#673ab7",
  "#190B00",
];
export const CategoryResult = [
  {
    name: "Category 1",
    Description: "Description 1"
  },
  {
    name: "Category 2",
    Description: "Description 2"
  },
  {
    name: "Category 3",
    Description: "Description 3"
  },
  {
    name: "Category 4",
    Description: "Description 4"
  },
];
export const CourseResult = [
  {
    name: "Course 1",
    Description: "Description 1"
  },
  {
    name: "Course 2",
    Description: "Description 2"
  },
  {
    name: "Course 3",
    Description: "Description 3"
  },
  {
    name: "Course 4",
    Description: "Description 4"
  },
];
export const QueryResult = [
  {
    date: "05/28/2021",
    datapoints: "300,476",
    locations: "Atlanta, Charlotte",
    gender: "F",
    age: "20 to 35",
    type: "Podcast",
    filters: 7,
  },
  {
    date: "03/16/2021",
    datapoints: "10,256,209",
    locations: "New York",
    gender: "M",
    age: "18 to 24",
    type: "Musician",
    filters: 0,
  },
  {
    date: "03/16/2021",
    datapoints: "1,024,028",
    locations: "Miami, Orlando",
    gender: "M,F",
    age: "20 to 35",
    type: "Youtube",
    filters: 0,
  },
  {
    date: "01/28/2021",
    datapoints: "12,890",
    locations: "Seattle, Tacoma",
    gender: "F",
    age: "40 to 65",
    type: "Musician",
    filters: 3,
  },
];
export const sampleData = {
  watching: [{
      name: "Action",
      data: [
        {
          name: "NCIS",
          value: 15.3,
        },
        {
          name: "FBI",
          value: 12.55,
        },
        {
          name: "Blue Blods",
          value: 11.96,
        },
        {
          name: "Chicago Fire",
          value: 11.7,
        },
        {
          name: "Chicago PD",
          value: 11.23,
        },
      ],
      drilldown: "Action",
      
        
     
    },
    {
      name: "Drama",
      data: [
        { name: "This is Us", value: 11.55 },

        {
          name: "Chicago Med",
          value: 11.22,
        },
        {
          name: "Good Doctor",
          value: 10.8,
        },
        {
          name: "Bull",
          value: 10.6,
        },
      ],
      drilldown: "Drama",
      
        
     
    },
    {
      name: "Comedy",
      data: [{ name: "Young Sheldon", value: 11.45 }],
      drilldown: "Comedy", 
      
      
      
   
    },
  ]
};
export const genreData = (() => {

  let arr = [];
  sampleData.watching.map((genre, i) => {
    let totals = 0;
    genre.data.map((show, i) => {
      totals += show.value;
     
    });
    arr.push({ name: genre.name, value: totals, drilldown: genre.name.toLowerCase() });
 
  });

  const g = [{ name: "Genre", data: arr, drilldown: "showsbygenre" }];

  return g;
  
})();
export const showsByGenre = {id: "showsbygenre", name: "shows", data: [
  {
    name: "Action",
    data: [
      {
        name: "NCIS",
        value: 15.3,
      },
      {
        name: "FBI",
        value: 12.55,
      },
      {
        name: "Blue Blods",
        value: 11.96,
      },
      {
        name: "Chicago Fire",
        value: 11.7,
      },
      {
        name: "Chicago PD",
        value: 11.23,
      },
    ],
    id: "action",
    
      
      
      
 
  },
  {
    name: "Drama",
    data: [
      { name: "This is Us", value: 11.55 },

      {
        name: "Chicago Med",
        value: 11.22,
      },
      {
        name: "The Good Doctor",
        value: 10.8,
      },
      {
        name: "Bull",
        value: 10.6,
      },
    ],
    id: "drama",
    
      
      
      
    },

  {
    name: "Comedy",
    data: [{ name: "Young Sheldon", value: 11.45 }],
    id: "comedy",
    
      
      
      
    },
 
]
}
export const drilldownData = { series: [showsByGenre].concat(showsByGenre.data) };
export const tabLabels = ["watching", "listening", "buying", "visiting"];
export const tabData = [
  {
    name: "watching",
    icon: "home",
    content: (
     ""
    ),
  },
  {
    name: "Profile",
    icon: "user",
    content: "",
  },
  {
    name: "Contact",
    icon: "phone",
    content: "",
  },

  {
    name: "Message",
    icon: "envelope",
    content: "",
  },
];

export const DummyTable = ({ series }) => {
  if (typeof series == "undefined" || typeof series.data == "undefined") {
    series = genreData[0];
}
  return series ? (
    <Table responsive className="header-border verticle-middle">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">{"Name"}</th>
          <th scope="col">Popularity</th>
        </tr>
      </thead>
      <tbody>
        {series.data
          ? series.data.map((entry, i) => {
              return (
                <tr key={"entry-" + i}>
                  <th>{i + 1}</th>
                  <td>{entry.name}</td>
                  <td>
                    <ProgressBar
                      now={(entry.value / series.data[0].value) * 100}
                      variant="dark"
                    />
                  </td>
                  <td>
                    <Badge variant="dark text-white">
                      {entry.value.toFixed(2) ?? series.name}
                    </Badge>
                  </td>
                </tr>
              );
            })
          : ""}
      </tbody>
    </Table>
  ) : (
    ""
  );
}

function getScript(url, cb) {
  const script = document.createElement("script");
  script.src = url;
  script.onload = cb;
  document.head.appendChild(script);
}

