/* eslint-disable */
import React, { Fragment } from "react";

/// Components
import Markup from "./components";

/// Style
import "./assets/css/style.css";
import "./assets/vendor/bootstrap-select/dist/css/bootstrap-select.min.css";

import { withResizeDetector } from "react-resize-detector";

const App = ({ width }) => {
   const body = document.querySelector("body");

   width >= 1200
      ? body.setAttribute("data-sidebar-style", "full")
      : width <= 1199 && width >= 767
      ? body.setAttribute("data-sidebar-style", "mini")
      : body.setAttribute("data-sidebar-style", "overlay");
   return (
      <Fragment>
         <Markup />
      </Fragment>
   );
};

export default withResizeDetector(App);
