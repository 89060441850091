/* eslint-disable */
import React from "react";
/* eslint-disable */
import ReactDOM from "react-dom";
import App from "./App";

ReactDOM.render( <><App />{/* <React.StrictMode>
      <App />
</React.StrictMode>*/}</>,
   document.getElementById("root")
);
